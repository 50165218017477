import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const YouAndWeeLandingPage = () => (
  <PhysicianLandingPage
    physician="Dr. Wallen"
    institution="You And Wee"
    referralCode="YOUANDWEE"
    physicianURL="https://youandwee.com"
  />
)

export default YouAndWeeLandingPage
